<template>
    <div>
        <v-dialog v-model="visible" transition="dialog-top-transition" scrollable persistent max-width="700">
            <v-card class="modal-maincard">

                <v-toolbar dark flat dense>
                    <v-toolbar-title>{{ $t(this.title) }}</v-toolbar-title>
                </v-toolbar>

                <v-card-text class="wrapperFormModal">
                    <v-card flat>
                        <v-card-text>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Избирательный_участок") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <div class="onlyReadData">
                                        {{ getElectionPartName(electionPart) }}
                                    </div>                                    
                                </v-col>
                            </v-row>

                            <v-row no-gutters v-if="type !== 3">
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Отчет_на") }}</label>
                                </v-col>
                                <v-col cols="12" sm="12" md="8">
                                    <v-menu 
                                        v-model="dateMenuVisible"
                                        :close-on-content-click="false"
                                        :nudge-right="40"
                                        transition="scale-transition"
                                        offset-y
                                        min-width="290"
                                    >
                                        <template v-slot:activator="{ on, attrs }">
                                            <v-text-field
                                                :value="exportDate | formattedDate"
                                                prepend-icon="mdi-calendar"
                                                readonly
                                                v-bind="attrs"
                                                v-on="on" 
                                                hide-details
                                                required
                                                outlined
                                                dense
                                                class="datepick-input"
                                                clearable
                                                @click:clear="exportDate= null"
                                            >
                                            </v-text-field>
                                        </template>

                                        <v-date-picker 
                                            v-model="exportDate"
                                            @input="dateMenuVisible = false"
                                            color="teal"
                                            :first-day-of-week="1"
                                        >
                                        </v-date-picker>
                                    </v-menu>
                                </v-col>
                            </v-row>

                            <v-row no-gutters>
                                <v-col cols="12" sm="12" md="4">
                                    <label class="f-label">{{ $t("Сортировать_по") }}</label>
                                </v-col>
                                <v-col  cols="12" sm="12" md="8">
                                    <v-autocomplete
                                        :items="sorts"
                                        v-model="sort"
                                        item-text="name"
                                        item-value="id"
                                        required
                                        outlined
                                        dense
                                        append-icon="fas fa-chevron-down"
                                    >
                                    </v-autocomplete>
                                </v-col>
                            </v-row>

                        </v-card-text>
                    </v-card>  
                </v-card-text>

                <v-card-actions>
                    <v-spacer></v-spacer>
                    <v-btn
                        v-if="valid"
                        color="cyan" 
                        text 
                        depressed
                        @click="ok" 
                        >
                        {{ $t("Сформировать") }}
                    </v-btn>
                    <v-btn 
                        color="blue-grey" 
                        text
                        depressed 
                        @click="cancel"
                    >
                        {{ $t("Отмена") }}
                    </v-btn>

                </v-card-actions>

            </v-card>
        </v-dialog>
    </div>
</template>

<script>
import sys from '@/services/system';

export default {
    name: "SelectUikExportParamsDlg",
    asyncComputed: {
    },
    computed: {
        valid() {
            return this.exportDate != null;
        },
        sorts() {
            return [
                { id: 0, name: 'Фамилия_Имя_Отчество' },
                { id: 1, name: 'Адрес' }
            ];
        }
    },
    data () {
        return {
            title: "Параметры_отчета",            
            visible: false,            
            resolve: null,
            options: {
                color: 'grey lighten-3',
                width: 400,
                zIndex: 200,
                noconfirm: false,
            },

            type: null,

            electionPart: null,
            exportDate: null,
            sort: 0,

            dateMenuVisible: false
        }
    },
    methods: {
        async open(electionPart, type) {
            this.type = type;
            this.exportDate = type === 3 ? '2024-10-06' : this.$moment().format('YYYY-MM-DD');
            this.sort = 0;
            this.electionPart = electionPart;

            this.visible = true;
            
            return new Promise((resolve) => {
                this.resolve = resolve;
            });
        },
        ok() {
            this.visible = false;
            this.dateMenuVisible = false;
            this.resolve({ id: this.electionPart.uuid, date: sys.dateFormat(this.exportDate, 'DD.MM.YYYY'), order: this.sort });
        },
        cancel() {
            this.visible = false;
            this.dateMenuVisible = false;
            this.resolve({ isCancelled: true, message: "Действие_отменено" }); 
        },
        getElectionPartName(item){
            if(item == null) return '';

            if (this.$i18n.locale === 'ru')
                return item.nameRu;
            else
                return item.nameKz;
        },
    },
    filters: {
        formattedDate: function (value) {
            return sys.dateFormat(value, 'DD.MM.YYYY') ?? "";
        }
    },
}
</script>